// src/components/dashboard.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../dashboard.css'; // Externe CSS-Datei für das Styling

// PDF-Dateien importieren
import Zeugnisbwd from '../pdfs/zeugnisbwd.pdf';
import Zeugnisgibb from '../pdfs/zeugnisgibb.pdf';
import Lebenslauf from '../pdfs/lebenslauf.pdf';

const Dashboard = () => {
  const [selectedPdf, setSelectedPdf] = useState(null);
  const navigate = useNavigate();

  // Funktion zum Öffnen der PDFs
  const openPdf = (pdf) => {
    setSelectedPdf(pdf);
  };

  // Funktion zum Schließen der PDF-Vorschau
  const closePreview = () => {
    setSelectedPdf(null);
  };

  // Logout-Funktion
  const handleLogout = () => {
    localStorage.removeItem('isAuthenticated'); // Authentifizierungsstatus entfernen
    navigate('/login'); // Weiterleitung zur Login-Seite
  };

  return (
    <div className="dashboard">
      <h2>Dashboard</h2>

      {/* Logout-Button */}
      <button onClick={handleLogout} className="btn btn-danger logout-btn">
        Logout
      </button>

      <div className="pdf-boxes">
        <div className="pdf-box" onClick={() => openPdf(Zeugnisbwd)}>
          <h3>Zeugnis bwd</h3>
        </div>

        <div className="pdf-box" onClick={() => openPdf(Lebenslauf)}>
          <h3>Lebenslauf</h3>
        </div>

        <div className="pdf-box" onClick={() => openPdf(Zeugnisgibb)}>
          <h3>Zeugnis gibb</h3>
        </div>
      </div>

      {/* PDF-Vorschau */}
      {selectedPdf && (
        <div className="pdf-preview">
          <button className="close-btn" onClick={closePreview}>X Schließen</button>
          <iframe
            src={selectedPdf}
            style={{ width: '80%', height: '80vh', border: 'none' }}
            title="PDF Preview"
          />
          <a href={selectedPdf} download className="download-btn">Download PDF</a>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
