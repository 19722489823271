import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'; // useNavigate importieren

// Importiere CSS in der richtigen Reihenfolge
import 'normalize.css';
import './animate.css';
import 'bootstrap/dist/css/bootstrap.css';
import './img/icons/css/ionicons.css';
import './img/font-awesome/css/font-awesome.css';
import 'lightbox2/dist/css/lightbox.min.css';
import './style.css';

// Importiere JS-Libraries
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import './libs/easing.js';
import 'lightbox2/dist/js/lightbox.min.js';

import * as serviceWorker from './serviceWorker';

// Importiere Komponenten
import Navbar from './components/navbar.jsx';
import Intro from './components/intro.jsx';
import About from './components/about.jsx';
import Portfolio from './components/portfolio.jsx';
import Contact from './components/contact.jsx';
import BackToTop from './components/back-top.jsx';
import Preloader from './components/preloader';
import Education from './components/education.jsx';
import PrivateRoute from './components/PrivateRoute'; // PrivateRoute importieren

// Importiere deine neuen Seiten
import Login from './components/login.jsx';
import Dashboard from './components/dashboard.jsx';

// LoginPage-Komponente mit Zurück-Pfeil
const LoginPage = () => {
  const navigate = useNavigate(); // Verwende useNavigate für die Navigation

  return (
    <div className="login-page">
      {/* Zurück-Pfeil */}
      <div className="back-arrow" onClick={() => navigate("/")}>
        ← Go Back
      </div>
      {/* Login-Komponente */}
      <Login />
    </div>
  );
};

// Haupt-Rendering
ReactDOM.render(
  <Router>
    <Routes>
      {/* Route für die Startseite */}
      <Route path="/" element={
        <>
          <Navbar />
          <Intro />
          <About />
          <Portfolio />
          <Education />
          <Contact />
          <BackToTop />
          <Preloader />
        </>
      } />

      {/* Route für die Login-Seite */}
      <Route path="/login" element={<LoginPage />} />

      {/* Private Route für das Dashboard */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Dashboard />
          </PrivateRoute>
        }
      />
    </Routes>
  </Router>,
  document.getElementById('root')
);

// Service Worker abmelden
serviceWorker.unregister();
