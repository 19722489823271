import React from "react";

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      skills: [
        { 
          id: "HTML5_skill", 
          content: "HTML5", 
          porcentage: "85%", 
          value: "85" 
        },
        { 
          id: "CSS3_skill", 
          content: "CSS3", 
          porcentage: "90%", 
          value: "90" 
        },
        { 
          id: "JavaScript_skill",
          content: "JavaScript",
          porcentage: "80%",
          value: "80"
        },
        { 
          id: "PHP_skill", 
          content: "PHP", 
          porcentage: "60%", 
          value: "60" 
        },
        {
          id: "ReactJS_skill",
          content: "ReactJS",
          porcentage: "75%",
          value: "75"
        },
        {
          id: "Bootstrap_skill",
          content: "Bootstrap",
          porcentage: "65%",
          value: "65"
        },
        {
          id: "Python_skill",
          content: "Python",
          porcentage: "85%",
          value: "85"
        },
        {
          id: "C#_skill",
          content: "C#",
          porcentage: "60%",
          value: "60"
        }
      ],
      about_me: [
        {
          id: "first-p-about",
          content:
            "My name is Jonas Graf, and I am 18 years old. Currently, I am in my third year of training as a computer scientist at bwd and gibb. My passion for computer science began at a young age, and this early enthusiasm has driven me to pursue a career in the field, where I continue to develop my skills and knowledge."
        },
        {
          id: "second-p-about",
          content:
            "I am a motivated application developer with a passion for learning and growing in the tech industry. I continually seek opportunities to improve my skills and stay updated with the latest technologies. My goal is to create innovative and efficient software solutions while constantly challenging myself to learn more."
        },
        {
          id: "third-p-about",
          content:
            "In addition to my passion for IT, football is a significant part of my life. I began playing at a very young age and continue to do so today. Sports, particularly football, have always been an essential aspect of my life, and they remain a constant source of energy and focus for me."
        }
      ]
    };
  }

  render() {
    return (
      <section id="about" className="about-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="box-shadow-full">
                <div className="row">
                  <div className="col-md-6">
                    <div className="row">
                      <div
                        className="col-sm-6 col-md-5"
                        style={{ margin: "0 auto" }}
                      >
                        <div
                          className="about-img"
                          style={{ textAlign: "center" }}
                        >
                          <img
                            className="img-fluid rounded b-shadow-a"
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                    <div className="skill-mf">
                      {/* <p className="title-s">Skill</p> */}
                      {this.state.skills.map(skill => {
                        return (
                          <React.Fragment key={skill.id}>
                            <span>{skill.content}</span>{" "}
                            <span className="pull-right">
                              {skill.porcentage}
                            </span>
                            <div className="progress">
                              <div
                                className="progress-bar"
                                role="progressbar"
                                style={{ width: skill.porcentage }}
                                aria-valuenow={skill.value}
                                aria-valuemin="0"
                                aria-valuemax="100"
                              ></div>
                            </div>
                          </React.Fragment>
                        );
                      })}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="about-me pt-4 pt-md-0">
                      <div className="title-box-2">
                        <h5 className="title-left">About Me</h5>
                      </div>
                      {this.state.about_me.map(content => {
                        return (
                          <p className="lead" key={content.id}>
                            {content.content}
                          </p>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
