import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import '../login.css'; // Externe CSS-Datei für das Styling des Formulars

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // Beispiel für die einfache Authentifizierung
    if (username === 'useradmin' && password === '20Jonas24') {
      // Authentifizierungsstatus in localStorage speichern
      localStorage.setItem('isAuthenticated', 'true');
      navigate('/dashboard'); // Weiterleitung zum Dashboard bei erfolgreichem Login
    } else {
      alert('Invalid login credentials');
    }
  };

  return (
    <div className="login-page">
      <div className="login-form-container">
        <h2 className="text-center">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="username">Username:</label>
            <input
              type="text"
              className="form-control"
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password:</label>
            <input
              type="password"
              className="form-control"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary btn-block">Login</button>
        </form>
      </div>
    </div>
  );
};

export default Login;
