import React from "react";

//import stock
import stock6 from "../img/sokoban.png";
import stock7 from "../img/hangman.png";
import stock8 from "../img/wastelessdelights.png";

class Portfolio extends React.Component {
  handleButtonClick(url) {
    window.open(url, "_blank");
  }

  render() {
    return (
      <section id="work" className="portfolio-mf sect-pt4 route">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="title-box text-center">
                <h3 className="title-a">Portfolio</h3>
                <p className="subtitle-a">
                  Here are some of the projects I've worked on in the last 3 years.
                </p>
                <div className="line-mf"></div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="work-box">
                <a href={stock6} data-lightbox="gallery-vmarine">
                  <div className="work-img">
                    <img src={stock6} alt="" className="img-fluid" />
                  </div>
                </a>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-8">
                      <h2 className="w-title">Sokoban</h2>
                      <div className="w-more">
                        <span className="w-ctegory">
                          HTML5 CSS3 JavaScript
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="w-like">
                        <button 
                          onClick={() => this.handleButtonClick("https://github.com/jgr135591/Sokoban")} 
                          className="btn btn-primary"
                        >
                          View on GitHub
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={stock8} data-lightbox="gallery-aguadeluz">
                  <div className="work-img">
                    <img src={stock8} alt="" className="img-fluid" />
                  </div>
                </a>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-8">
                      <h2 className="w-title">Wasteless Delights</h2>
                      <div className="w-more">
                        <span className="w-ctegory">
                          Python
                        </span>{" "}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="w-like">
                        <button 
                          onClick={() => this.handleButtonClick("https://github.com/jgr135591/Wasteless-Delights")} 
                          className="btn btn-primary"
                        >
                          View on GitHub
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="work-box">
                <a href={stock7} data-lightbox="gallery-todo">
                  <div className="work-img">
                    <img src={stock7} alt="" className="img-fluid" />
                  </div>
                </a>
                <div className="work-content">
                  <div className="row">
                    <div className="col-sm-8">
                      <h2 className="w-title">Hangman</h2>
                      <div className="w-more">
                        <span className="w-ctegory">
                          Python
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="w-like">
                        <button 
                          onClick={() => this.handleButtonClick("https://github.com/jgr135591/Hangman")} 
                          className="btn btn-primary"
                        >
                          View on GitHub
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Portfolio;
